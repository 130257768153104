import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import Home from './site/Home';
import Login from './site/Login';
import Backoffice from './site/Backoffice';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import common_it from "./translations/it/common.json";
import common_en from "./translations/en/common.json";

var userLang = navigator.language || navigator.userLanguage;

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: userLang,                              // language to use
    resources: {
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        it: {
            common: common_it
        },
    },
});

const Page404 = ({ location }) => {(
  window.location.href = '/'
)};

const reload = () => window.location.reload();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
      <I18nextProvider i18n={i18next}>
        <div>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/backoffice">
                <Backoffice />
              </Route>
              <Route component={Page404} />
              <Route path="/robots.txt" onEnter={reload} />
            </Switch>
        </div>
      </I18nextProvider>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
